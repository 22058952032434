import React from "react";
import useCitiesData from "../../hooks/use-cities-data";
import styles from "./compare.module.scss";
import { useIsMobile900, useIsTablet } from "../../hooks/mobile.js";
import Select, { components } from "react-select";
import { getNumberWithOrdinalWithSuperscript } from "../../hooks/utils.js";

const NUMBER_CITIES_TO_SHOW = 10;

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {!props.selectProps.menuIsOpen && (
          <svg
            aria-hidden="true"
            focusable="false"
            className={styles.chevronDown}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
            ></path>
          </svg>
        )}
        {props.selectProps.menuIsOpen && (
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className={styles.chevronUp}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"
            ></path>
          </svg>
        )}
      </components.DropdownIndicator>
    )
  );
};

const iconPlus = (
  <svg
    className={styles.plus}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
    ></path>
  </svg>
);

const iconMinus = (
  <svg
    className={styles.minus}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
    ></path>
  </svg>
);

const ICON_SIZE = 20;

export default function Compare() {
  let { citiesData, scoreColumns } = useCitiesData();
  const [selectedParameter, setSelectedParameter] = React.useState(null);
  const [sortedCitiesData, setSortedCitiesData] = React.useState(null);
  const [allCitiesData, setAllCitiesData] = React.useState(null);

  const isMobile = useIsMobile900();
  const isTablet = useIsTablet();

  React.useEffect(() => {
    if (citiesData && scoreColumns) {
      const data = citiesData.map((d) => {
        return {
          ...d,
          city: d.city ? d.city : d.country,
        };
      });

      setSelectedParameter({
        value: scoreColumns[0].column,
        label: scoreColumns[0].name,
      });

      const sorted = data
        .sort((a, b) => {
          const byValue = b[scoreColumns[0].column] - a[scoreColumns[0].column];
          const byName = a.city.localeCompare(b.city);
          return byValue || byName;
        })
        .map((d) => d[scoreColumns[0].column]);
      const uniqueSorted = sorted.filter((d, i) => sorted.indexOf(d) === i);

      const rankedData = data.map((d) => {
        return {
          ...d,
          rank: uniqueSorted.indexOf(d[scoreColumns[0].column]) + 1, // for dense ranking
        };
      });

      setAllCitiesData(rankedData);
      setSortedCitiesData(rankedData);
    }
  }, [citiesData, scoreColumns]);

  return (
    <div className={styles.compare}>
      <h2 className={styles.embedH2}>Global Citizenship Program Index</h2>
      <div className={styles.compareEmbedContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.parameterSelection}>
            <h3 className={styles.topH3}>
              Top {NUMBER_CITIES_TO_SHOW} programs for:
            </h3>
            {scoreColumns && selectedParameter && (
              <Select
                options={scoreColumns.map((d) => {
                  return { value: d.column, label: d.name };
                })}
                defaultValue={selectedParameter}
                components={{ DropdownIndicator }}
                onChange={(option) => {
                  setSelectedParameter({
                    value: option.value,
                    label: option.label,
                  });

                  const sorted = allCitiesData
                    .sort((a, b) => {
                      const byValue = b[option.value] - a[option.value];
                      const byName = a.city.localeCompare(b.city);
                      return byValue || byName;
                    })
                    .map((d) => d[option.value]);
                  const uniqueSorted = sorted.filter(
                    (d, i) => sorted.indexOf(d) === i
                  );

                  const rankedData = allCitiesData.map((d) => {
                    return {
                      ...d,
                      rank: uniqueSorted.indexOf(d[option.value]) + 1, // for dense ranking
                    };
                  });
                  setSortedCitiesData(rankedData);
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 100,
                  }),
                  control: (provided) => ({
                    ...provided,
                    background: "#405363",
                    border: "none",
                    boxShadow: "none",
                    borderRadius: 0,
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    background: "#fff",
                    color: "#405363",
                    padding: isMobile ? 14 : 12,
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#fff",
                    fontFamily: "Garamond Regular",
                    fontSize: isTablet ? 26 : 36,
                    paddingLeft: 6,
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#fff",
                    fontFamily: "Garamond Regular",
                    fontSize: isTablet ? 26 : 36,
                    paddingLeft: 6,
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: "10px 0",
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    background: "#f7f7f7",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? "#C1C5C8"
                      : state.isSelected
                      ? "#405363"
                      : null,
                    color: state.isFocused
                      ? "#405363"
                      : state.isSelected
                      ? "#fff"
                      : "#405363",
                  }),
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.rightColumn}>
          <table className={styles.compareTable}>
            <thead>
              <tr className={styles.compareTableHeader}>
                <th className={styles.headerRank}>Rank</th>
                <th className={styles.headerCity}>Program</th>
                <th className={styles.headerKey}></th>
                <th className={styles.headerScoreSelected}>
                  <span
                    className={styles.headerPill}
                    style={{
                      backgroundColor: selectedParameter
                        ? scoreColumns.find(
                            (e) => e.column === selectedParameter.value
                          ).color
                        : "#fff",
                    }}
                  ></span>
                  {selectedParameter ? selectedParameter.label : ""}
                </th>
                <th className={styles.headerScore}>Total Score</th>
              </tr>
            </thead>
            <tbody>
              {selectedParameter &&
                scoreColumns &&
                sortedCitiesData &&
                sortedCitiesData.slice(0, NUMBER_CITIES_TO_SHOW).map((d, i) => {
                  return (
                    <tr key={d.city}>
                      <td className={styles.rankColumn}>
                        {getNumberWithOrdinalWithSuperscript(d.rank)}
                      </td>
                      <td className={styles.citynameColumn}>{d.countryDisplay}</td>
                      <td className={styles.barchartColumn}>
                        <div
                          aria-hidden="true"
                          className={styles.barChartLabel}
                        >
                          {d.countryDisplay}
                        </div>
                        <div className={styles.barchartBackground}></div>
                        <div
                          className={styles.barchartForeground}
                          style={{
                            width: `calc(${
                              (d[selectedParameter.value] / 10) * 100
                            }% - 30px)`,
                            gridTemplateColumns: [d[selectedParameter.value]]
                              .map((s) => s + "fr ")
                              .join(" "),
                          }}
                        >
                          <div
                            className={styles.barchartElement}
                            data-label={selectedParameter.value}
                            style={{
                              backgroundColor: scoreColumns.find(
                                (e) => e.column === selectedParameter.value
                              ).color,
                              zIndex: sortedCitiesData.length - i,
                            }}
                          >
                            {selectedParameter.label +
                              ": " +
                              d[selectedParameter.value]}
                          </div>
                        </div>
                      </td>
                      <td className={styles.scoreSelectedColumn}>
                        {d[selectedParameter.value].toFixed(0)}
                      </td>
                      <td className={styles.scoreColumn}>
                        <span className={styles.scoreColumnExpanded}>
                          {d.totalScore.toFixed(0)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
