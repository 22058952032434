import React from "react";
import useCitiesData from "../../hooks/use-cities-data";
import styles from "./all.module.scss";
import { useIsMobile900 } from "../../hooks/mobile.js";
import { getNumberWithOrdinalWithSuperscript } from "../../hooks/utils.js";

const iconPlus = (
  <svg
    className={styles.plus}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
    ></path>
  </svg>
);

const iconMinus = (
  <svg
    className={styles.minus}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
    ></path>
  </svg>
);

const iconTick = (
  <svg
    className={styles.check}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0
        0
        512 512"
  >
    <path
      fill="#fff"
      d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
    ></path>
  </svg>
);

const ICON_SIZE = 24;

export default function AllEmbed() {
  let { citiesData, scoreColumns } = useCitiesData();
  const [allCitiesData, setAllCitiesData] = React.useState(null);
  const [sortedCitiesData, setSortedCitiesData] = React.useState(null);
  const [selectedParameters, setSelectedParameters] = React.useState([]);
  const [disabledParameters, setDisabledParameters] = React.useState(false);
  const [collapsedParameters, setCollapsedParameters] = React.useState(true);
  const [expandedCities, setExpandedCities] = React.useState([]);
  const [hoveredParameter, setHoveredParameter] = React.useState(null);

  const scrollRef = React.useRef(null);
  const selectRef = React.useRef(null);

  const isMobile = useIsMobile900();

  React.useEffect(() => {
    if (citiesData && scoreColumns) {
      const data = citiesData.map((d) => {
        return {
          total: d.totalScore,
          scores: scoreColumns.map((e) => {
            return {
              score: e.column,
              name: e.name,
              icon: e.icon,
              value: d[e.column],
              color: e.color,
            };
          }),
          slug: d.slug,
          originalRank: d.rank,
          city: d.city ? d.city : d.country,
          countryDisplay: d.countryDisplay
        };
      });

      // const sorted = data.sort((a, b) => b.total - a.total).map((d) => d.total);
      const sorted = data.sort((a, b) => {
        const byTotal = b.total - a.total;
        const byCountry = a.city.localeCompare(b.city);
        return byTotal || byCountry;
      }).map((d) => d.total);
      const uniqueSorted = sorted.filter((d, i) => sorted.indexOf(d) === i);

      const rankedData = data.map((d) => {
        return {
          ...d,
          rank: uniqueSorted.indexOf(d.total) + 1, // for dense ranking
        };
      });

      setAllCitiesData(rankedData);
      setSortedCitiesData(rankedData);
    }
  }, [citiesData, scoreColumns]);

  const handleExpand = (city) => {
    if (isMobile) {
      // if d already in expanded list, remove it.
      // if it's not, add it.
      const expanded = expandedCities.find((d) => d === city)
        ? expandedCities.filter((d) => d !== city)
        : [...expandedCities, city];
      setExpandedCities(expanded);
    }
  };

  const handleCheck = (e) => {
    const selected = e.target.checked
      ? [...selectedParameters, e.target.name]
      : selectedParameters.filter((d) => d !== e.target.name);
    selected.length >= 3
      ? setDisabledParameters(true)
      : setDisabledParameters(false);
    setSelectedParameters(selected);

    if (selected.length === 0) {
      setSortedCitiesData(allCitiesData);
    } else {
      const data = [...sortedCitiesData];

      const sorted = data
        .map((d) => {
          return {
            ...d,
            newTotal: d.scores
              .filter((e) => selected.find((f) => f === e.score))
              .reduce((sum, e) => {
                return sum + e.value;
              }, 0)
              .toFixed(0),
          };
        })
        .sort((a, b) => {
          return b.newTotal - a.newTotal || a.city.localeCompare(b.city);
        });

      const sortedTotals = sorted.map((d) => d.newTotal);
      const uniqueSorted = sortedTotals.filter(
        (d, i) => sortedTotals.indexOf(d) === i
      );

      const newData = sorted.map((d) => {
        return {
          ...d,
          total: d.newTotal,
          rank: uniqueSorted.indexOf(d.newTotal) + 1, // for dense ranking
        };
      });

      setSortedCitiesData(newData);
    }
  };

  const resetCheck = () => {
    Array.from(selectRef.current.children).map((d) => (d.checked = false));
    setSelectedParameters([]);
    setDisabledParameters(false);
    setSortedCitiesData(allCitiesData);
  };

  const handleParameterControl = () => {
    if (isMobile) {
      setCollapsedParameters(!collapsedParameters);
    }
  };

  const handleParameterHover = (e, icon, name, value, city) => {
    if (!e) {
      setHoveredParameter(e);
    } else {
      setHoveredParameter({
        x: 0,
        y: 0,
        icon: icon,
        name: name,
        value: value,
        city: city,
      });
    }
  };

  const executeScroll = () => scrollRef.current.scrollIntoView();

  return (
    <div className={styles.allEmbed}>
      <h2 className={styles.embedH2}>Global Citizenship Program Index</h2>
      <div className={styles.allEmbedContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.parameterSelection}>
            <h3 onClick={handleParameterControl}>
              Select up to 3 factors to create your own index
              {isMobile && (
                <span className={styles.mobileCollapsedControl}>
                  {collapsedParameters && iconPlus}
                  {!collapsedParameters && iconMinus}
                </span>
              )}
            </h3>
            <div
              className={styles.mobileCollapsed}
              data-collapsed={collapsedParameters}
            >
              <ul className={styles.selectionList} ref={selectRef}>
                {scoreColumns &&
                  scoreColumns.map((d) => {
                    return (
                      <li
                        title={
                          disabledParameters
                            ? "Deselect a factor to choose another"
                            : ""
                        }
                        key={d.column}
                      >
                        <input
                          type="checkbox"
                          id={d.column}
                          name={d.column}
                          className={styles.parameterCheckbox}
                          onClick={handleCheck}
                          disabled={
                            disabledParameters
                              ? selectedParameters.find((e) => e === d.column)
                                ? false
                                : true
                              : false
                          }
                        />
                        <span className={styles.parameterControls}>
                          <label htmlFor={d.column}>{d.name}</label>
                          <span
                            className={styles.checkboxControl}
                            style={{
                              borderColor: d.color,
                              backgroundColor:  d.color
                            }}
                            // style={{
                            //   borderColor: disabledParameters
                            //     ? selectedParameters.find((e) => e === d.column)
                            //       ? d.color
                            //       : "#C1C5C8"
                            //     : d.color,
                            //   backgroundColor: selectedParameters.find(
                            //     (e) => e === d.column
                            //   )
                            //     ? d.color
                            //     : "transparent",
                            // }}
                          >
                            {selectedParameters.find((e) => e === d.column) &&
                              iconTick}
                          </span>
                        </span>
                      </li>
                    );
                  })}
              </ul>
              <div className={styles.selectionButtons}>
                {isMobile && (
                  <button className={styles.button} onClick={executeScroll}>
                    See my index
                  </button>
                )}
                {selectedParameters.length > 0 && (
                  <button className={styles.buttonReset} onClick={resetCheck}>
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightColumn} ref={scrollRef}>
          <table className={styles.allTable}>
            <thead>
              <tr className={styles.allTableHeader}>
                <th className={styles.headerRank}>Rank</th>
                <th className={styles.headerCity}>Country</th>
                <th className={styles.headerKey}></th>
                <th className={styles.headerScore}>
                  {selectedParameters.length === 0 ? "Total Score" : "Score"}
                </th>
                {isMobile && <th className={styles.headerExpando}></th>}
              </tr>
            </thead>
            <tbody>
              {sortedCitiesData &&
                sortedCitiesData.map((d, i) => {
                  return (
                    <tr
                      key={d.city}
                      onClick={() => {
                        handleExpand(d.city);
                      }}
                    >
                      <td className={styles.rankColumn}>
                        {getNumberWithOrdinalWithSuperscript(d.rank)}
                      </td>
                      <td className={styles.citynameColumn}>{d.countryDisplay}</td>
                      <td className={styles.barchartColumn}>
                        <div
                          aria-hidden="true"
                          className={styles.barChartLabel}
                        >
                          {d.countryDisplay}
                        </div>
                        <div className={styles.barchartBackground}></div>
                        <div
                          className={styles.barchartForeground}
                          style={{
                            width: `${
                              (d.total /
                                (selectedParameters.length
                                  ? selectedParameters.length * 10
                                  : 100)) *
                              100
                            }%`,
                            gridTemplateColumns: d.scores
                              .filter((e) =>
                                selectedParameters.length > 0
                                  ? selectedParameters.find(
                                      (f) => f === e.score
                                    )
                                  : e
                              )
                              .map((s) => s.value + "fr ")
                              .join(" "),
                          }}
                        >
                          {d.scores
                            .filter((e) =>
                              selectedParameters.length > 0
                                ? selectedParameters.find((f) => f === e.score)
                                : e
                            )
                            .map((s, i) => {
                              return (
                                <div
                                  key={s.score}
                                  className={styles.barchartElement}
                                  data-label={s.score}
                                  style={{
                                    backgroundColor: s.color,
                                    zIndex: d.scores.length - i,
                                  }}
                                  data-tip={s.name + ": " + s.value}
                                  onMouseOver={(e) => {
                                    handleParameterHover(
                                      e,
                                      s.icon,
                                      s.name,
                                      s.value,
                                      d.city
                                    );
                                  }}
                                  onMouseOut={() => {
                                    handleParameterHover(null);
                                  }}
                                >
                                  {!isMobile &&
                                    hoveredParameter &&
                                    d.city === hoveredParameter.city &&
                                    hoveredParameter.name === s.name && (
                                      <div className={styles.parameterHover}>
                                        <div
                                          className={
                                            styles.parameterHoverContainer
                                          }
                                        >
                                          <span
                                            className={
                                              styles.parameterHoverContainerImage
                                            }
                                          >
                                            <img
                                              src={
                                                "data:image/svg+xml," +
                                                encodeURIComponent(
                                                  hoveredParameter.icon
                                                )
                                              }
                                              alt={hoveredParameter.score}
                                              className={styles.parameterIcon}
                                              style={{
                                                height: "auto",
                                                width: ICON_SIZE * 1.3,
                                              }}
                                              aria-hidden="true"
                                            />
                                          </span>
                                          <span
                                            className={
                                              styles.parameterHoverContainerName
                                            }
                                          >
                                            {hoveredParameter.name}
                                          </span>
                                          <span
                                            className={
                                              styles.parameterHoverContainerValue
                                            }
                                          >
                                            {hoveredParameter.value.toFixed(0)}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </div>
                        {isMobile && expandedCities.find((e) => e === d.city) && (
                          <table className={styles.expandedCityInfo}>
                            <tbody>
                              {d.scores
                                .filter((e) =>
                                  selectedParameters.length > 0
                                    ? selectedParameters.find(
                                        (f) => f === e.score
                                      )
                                    : e
                                )
                                .map((s, i) => {
                                  return (
                                    <tr key={s.score}>
                                      <td
                                        className={styles.expandedCityInfoIcon}
                                      >
                                        <img
                                          src={
                                            "data:image/svg+xml," +
                                            encodeURIComponent(s.icon)
                                          }
                                          alt={s.name}
                                          className={styles.parameterIcon}
                                          style={{
                                            height: "auto",
                                            width: ICON_SIZE,
                                          }}
                                          aria-hidden="true"
                                        />
                                      </td>
                                      <td
                                        className={styles.expandedCityInfoName}
                                      >
                                        {s.name}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        )}
                      </td>
                      <td className={styles.scoreColumn}>
                        {isMobile && expandedCities.find((e) => e === d.city) && (
                          <React.Fragment>
                            <span className={styles.scoreColumnExpanded}>
                              {d.total.toFixed(0)}
                            </span>
                            {d.scores
                              .filter((e) =>
                                selectedParameters.length > 0
                                  ? selectedParameters.find(
                                      (f) => f === e.score
                                    )
                                  : e
                              )
                              .map((s, i) => {
                                return (
                                  <span
                                    key={s.score}
                                    className={styles.scoreColumnExpanded}
                                  >
                                    {s.value.toFixed(0)}
                                  </span>
                                );
                              })}
                          </React.Fragment>
                        )}
                        {!(
                          isMobile && expandedCities.find((e) => e === d.city)
                        ) && (
                          <span className={styles.scoreColumnExpanded}>
                            {selectedParameters.length > 0 && (
                              <React.Fragment>
                                {d.scores
                                  .filter((e) =>
                                    selectedParameters.find(
                                      (f) => f === e.score
                                    )
                                  )
                                  .reduce((sum, e) => {
                                    return sum + e.value;
                                  }, 0)
                                  .toFixed(0)}
                              </React.Fragment>
                            )}
                            {selectedParameters.length === 0 && (
                              <React.Fragment>
                                {d.total.toFixed(0)}
                              </React.Fragment>
                            )}
                          </span>
                        )}
                      </td>
                      {isMobile && (
                        <td className={styles.expandoControl}>
                          <div>
                            {expandedCities.find((e) => e === d.city) &&
                              iconMinus}
                            {!expandedCities.find((e) => e === d.city) &&
                              iconPlus}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
